
html, body {

  font-size: 0.9em;
  font-family: 'Inter', 'Helvetica Neue', Arial;
  color: var(--ion-color-light);
  --ion-background-color: var(--ion-color-light);
}

th {

  text-align: left;
}

p {

  font-size: 15px;
  line-height: 1.5em;
}

.center-content {
  display: flex;
  align-items: center;
  justify-content: left;
}

.reorder-icon {

  padding-top: 3px;
  transform: scale(1.3);
}

.aligned-text {

  border-left: 1px solid var(--ion-color-primary);
  padding-left: 10px;
  padding-right: 10px;
}

.large-spinner {
 
  transform: scale(1.7);
}

.content-container {

  background-color: var(--ion-color-light);
  min-height: calc(100vh - 58px);
  padding: 20px;
  padding-top: 8px;
  padding-left: 20px;
}

.menu-avatar {

  margin-top: 20px;
}

div.template-warning {

  background-color: var(--ion-color-warning);
  color: var(--ion-color-light);
  padding: 10px;
  width: 98%;
  border-radius: 10px;
  margin-left: 10px;  
  margin-bottom: 5px;
}

ion-list-header {

    padding: 0px;
    padding-left: 10px;
    margin: 0px;
    font-size: 1.2em;
}

ion-button {

  text-transform: none;
  --ion-font-family: 'Inter', 'Helvetica Neue', Arial;
  --box-shadow: none; 
}

ion-button.basic {

  --border-radius: 5px;
  --border-style: solid;
  --border-width: 1px;
  --border-color: var(--ion-color-dark);
  --background: transparent;
  --padding-start: 12px;
  --padding-end: 12px;
  --margin: 10px;
  --box-shadow: none; 
  text-transform: none;
  font-family: 'Inter', 'Helvetica Neue', Arial;
  --ion-font-family: 'Inter', 'Helvetica Neue', Arial;
  color: var(--ion-color-dark);
}

ion-card {

  --background: transparent;   /* Transparent background */
  box-shadow: none; 
  color: var(--ion-color-dark);
  border: 1px solid var(--ion-color-medium);
  border-radius: 10px;
}

ion-card-title {

  color: var(--ion-color-primary);
  font-weight: normal;
  font-size: 1.2em;
}

div.mid-header {

  color: var(--ion-color-primary);
  font-weight: normal;
  font-size: 1.1em;
  margin-top: 20px;
}

h1 {

  padding-left: 3px;
  padding-bottom: 10px;
}

.link-anchor {
  
  color: var(--ion-color-primary);
}

.link {

  cursor: pointer;
}

.feature_role {

  margin-right: '8px';
  padding: '4px 12px';
  border-radius: '8px';
  width: '100px';
}

.centered-content-box {

  padding-left: 30px;
  max-width: 400px;
  font-size: 110%;
}

.centered-content {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;;
  height: 100%;
}

@media (min-width: 1000px) {

  .centered-content {

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;;
    height: 100%;
    position: relative;
  }
}

div.filled {

  color: var(--ion-color-dark);
  background-color: var(--ion-color-light);
  filter: brightness(115%);
  border: 1px solid rgba(var(--ion-color-light-tint-rgb), 0.3);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

ion-card.filled {

  color: var(--ion-color-dark);
  background-color: var(--ion-color-light);
  filter: brightness(115%);
  border: 1px solid rgba(var(--ion-color-light-tint-rgb), 0.3);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

ion-modal {

  --border-radius: 5px;
}

ion-col.content {

  min-height:100vh;
  padding: 20px;
}

div.description {

  margin: 15px;
  margin-bottom: 25px;
}

div.alert {

  font-weight: bold;
  margin-top: 15px;
}

.right-align {
  display: flex;
  justify-content: flex-end;
}

.fullscreen {

  width: 100%;
  height: 90%;
  height: 90vh;
  border: 0px;
  border-radius: 10px;
}

ion-reorder {

  vertical-align: top;
}

.transparent {
  background: transparent;
  --background: transparent;   /* Transparent background */
}

.pointer {

  cursor: pointer;
}

ion-img.icon {

  width: 50px;
  height: 50px;
  margin-left: 10px;
}

ion-segment-button ion-label {
  text-transform: none !important;
  font-size: 90% !important;
  font-family: 'Inter', 'Helvetica Neue', Arial !important;
}

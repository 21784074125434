@media only screen and (max-width: 600px) {
    .list-header, .list-content, .list-tags, .list-content-edit {
      width: 100%; /* Stack columns vertically on small screens */
    }
}

div.list-container {

  /* max-height: 70vh; /* Set the height to fill the viewport */
  overflow-y: scroll; /* Enable vertical scrolling */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling for touch devices */  
}

div.list-content {

    border-left: 1px solid var(--ion-color-primary);
    padding-left: 10px;
    padding-right: 10px;
    margin: 8px;
}

div.list-tags {

  padding-left: 10px;
  padding-right: 10px;
  margin: 8px;
}

div.list-header {

    padding: 0px;
    padding-left: 11px;
    margin: 0px;
    font-size: 1.05em;
    font-weight: 500;
  }
  

  div.list-content-edit {
  
    padding: 8px;
  }

  div.list-attribute {

    padding: 5px;
  }

  span.list-attribute-label {

    font-weight: 500;
    color: var(--ion-color-medium);
  }

  ion-toolbar.list {

    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;
    --inner-padding-end: 0;
    --inner-padding-start: 0;
    margin: 0;
    height: 35px;
  }

  ion-buttons.list {
    position: relative;
    top: -10px;
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;
    --inner-padding-end: 0;
    --inner-padding-start: 0;
    margin: 0;
  }

  ion-card.list {

    position: relative;
    --background: transparent;
    box-shadow: none; 
    color: var(--ion-color-dark);
    border: 1px solid var(--ion-color-medium);
    border-radius: 10px;
  }
  
  ion-card-title.list {
  
    color: var(--ion-color-primary);
    font-weight: normal;
    font-size: 1.1em;
    padding-right: 22px;
    padding-left: 5px;
  }
  
  ion-icon.list-edit-card {
    position: absolute;
    top: 15px;
    right: 15px;    
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  ion-icon.list-edit-table {
    position: absolute;
    top: 18px;
    right: 10px; /* Adjust as needed */
    transform: translateY(-50%); /* Center the icon vertically */
    cursor: pointer;
    width: 20px;
    height: 20px;
  }

  .list-row {
    position: relative;
    padding-right: 20px; /* Adjust the padding to ensure enough space for the edit icon */
  }
  
  span.tag {

    border-radius: 5px;
    background-color: var(--ion-color-light-tint);
    color: var(--ion-color-dark);
    padding: 4px;
    padding-left: 10px;
    padding-right: 10px;
  }
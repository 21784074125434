
/* Ensuring the button has a higher z-index than the menu */
.menu-toggle-button {
  z-index: 1000;
  margin-left: 15px;
  position: relative; /* z-index works only on positioned elements */
  z-index: 1000; /* Higher than the menu's z-index */
}

.menu-toggle-button-container {

    transform: translate3d(0,0,0);
    opacity: 0.99;
    filter: blur(0px);
}

/* Container for the entire layout */
.layout-component {
  display: flex;
  height: 100vh; /* Full viewport height */
}

/* Menu container styles */
.menu-component {
  width: 250px; /* Fixed width */
  height: 100%; /* Full height of the parent */
  /* transition: left 0.3s; /* Smooth transition for toggling */
  /* Additional styling for the menu (background, colors, etc.) */
}

/* Content container styles */
.page-component {
  flex-grow: 1; /* Takes up the remaining space */
  /* Additional styling for the content area */
  padding-bottom: 60px;
  margin-bottom: 0px;
  /* Fix the small padding at the bottom */
  min-height: 100.3vh;
}

@media screen and (max-width: 1000px) {
  .menu-component {
    width: 250px;
    position: fixed;
    left: -250px; /* Initially hidden */
    display: none;
    /* transition: left 0.3s; Smooth transition for toggling */
    z-index: 500; /* Ensure it's above other content */
  }

  .menu-component.visible {
      display: block;
      left: 0; /* Show the menu */
  }

  .page-component.menu-visible {
      margin-left: 250px; /* Move content when menu is visible */
  }

  .page-component {

    min-height: 100vh;
  }

  .page-component.menu-dimmed {
    filter: brightness(70%); /* Dim the brightness when menu is visible */
  }
}

.menu-container {

  
  border-right: 1px solid rgba(var(--ion-color-light-tint-rgb), 0.3);
  box-shadow: 10px 10px 10px var(--ion-color-light-shade);
  background-color: var(--ion-color-light);
  filter: brightness(110%);
  min-height: 100vh;
  overflow-y: auto;
}

.menu-main {

  font-weight: 500;
}

.menu-sub {

  opacity: 0.7;  
}

div.menu {

  border-right: 1px solid rgba(var(--ion-color-light-tint-rgb), 0.3);
  box-shadow: 10px 10px 10px var(--ion-color-light-shade);
  background-color: var(--ion-color-light);
  filter: brightness(110%);
  min-height: 100vh;
  margin: 0px;
  padding: 0px;
  padding-bottom: var(--app-menu-bottom-height);
}

.logo {

  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-bottom: 11px;
  margin-top: 12px;
}

ion-icon.menu {

  padding-right: 10px;
}

a.menu {

  white-space: nowrap;
  text-decoration: none;
}

.menu-sticky-top {
  position: fixed;
  top: 0;
  z-index: 900;
  left: 10px;
  width: 90%;
  max-height: calc(100vh - var(--app-menu-bottom-height));
  overflow-y: auto;
  background-color: var(--ion-color-light);
}

.menu-sticky-bottom {
  position: fixed;
  bottom: 0;
  padding-bottom: 20px;
  z-index: 900;
  left: 10px;
  height: var(--app-menu-bottom-height);
  background-color: var(--ion-color-light);
}

ion-popover.initiative {

  box-shadow: none;  
  --width: 225px;
  z-index: 10000;
}

div.initiative-menu {

  border-radius: 10px;
}

div.initiative {
  width: 225px;
}

div.initiative-list {
  width: 225px;
  padding: 0px;
  margin-bottom: 0px;
}

div.initiative-item-container {
  border-radius: 10px;
  border-style: solid;
  border-width: 0px;
  border-color: var(--ion-color-dark);
  background-color: transparent;
  margin-bottom: 0px;
  box-shadow: none;
  vertical-align: center;
  width: 225px;
  padding: 0px;
}

div.initiative-item {
  margin: 0px;
  padding: 5px;
  width: 100%;
}

div.initiative-item-button {

  border-radius: 5px;
  padding: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 110%;
  border-style: 1px solid var(--ion-color-dark);
}

div.initiative-item-button:hover {
  background-color: var(--ion-color-light);
  filter: brightness(130%);
  /* transition: background-color 0.3s, color 0.3s; */
}

div.initiative-settings {
  border-radius: 0px 0px 8px 9px;
  border-style: 0px;
  padding: 5px;
  width: 223px !important;
  background-color: var(--ion-color-light);
  font-size: 110%;
  filter: brightness(70%);
}

div.initiative-settings-button {

  border-radius: 6px;
  padding: 9px;
  border-style: 1px solid var(--ion-color-dark);
}

div.initiative-settings-button:hover {
  background-color: var(--ion-color-light);
  filter: brightness(130%);
  /* transition: background-color 0.3s, color 0.3s; */
}

ion-list.navigation {

  scale: 0.94;
  border: 0px;
  width: 230px;
}

ion-item.navigation-selected {

  color: var(--ion-color-primary);
}

ion-item.navigation-root {

}

ion-header,
ion-toolbar {
    --background: var(--ion-color-light) !important;
}

ion-title {

  padding-left: 0px;
}

ion-breadcrumbs {

  margin: 0px;
}

ion-breadcrumbs {

  padding-left: 0px;
}

ion-breadcrumb {

  padding-left: 0px;
  font-size: 1.1em;
  color: var(--ion-color-dark);
}



.form-modal {

  --border-radius: 5px;
  --height: auto;
  --min-height: 20%;
  --max-height: 80%;
  overflow: auto;  
}

.form {

  padding: 25px;
  background-color: var(--ion-color-light);
}

.form-element {

  margin-top: 10px;
  margin-bottom: 10px;
}

.form-element-title {

  margin-bottom: 15px;
  margin-top: 20px;
  margin-left: 2px;
  font-size: 110%;
  font-weight: 500;
  color: var(--ion-color-dark);
}

.form-element-text {

  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 2px;
  color: var(--ion-color-medium-shade);
}

.form-modal {

  --border-radius: 5px;
  --height: auto;
  --min-height: 20%;
  --max-height: 80%;
  overflow: auto;  
}

.form {

  padding: 25px;
  background-color: var(--ion-color-light);
}

.form-element {

  margin-top: 10px;
  margin-bottom: 10px;
}

.form-element-json {

  font-family: monospace !important;
}

.form-element-title {

  margin-bottom: 15px;
  margin-top: 20px;
  margin-left: 2px;
  font-size: 110%;
  font-weight: 500;
  color: var(--ion-color-dark);
}

.form-element-text {

  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 2px;
  color: var(--ion-color-medium-shade);
}

.form-file-upload {

  border: 1px dashed var(--ion-color-primary);
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 10px;
}

.form-textarea-container {
  display: flex;
  flex-direction: column;
}

.form-labels-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}


.login-form {

  --border-radius: 5px;
  width: 350px;
  text-align: left;
}

.login-error {

  border: 0px;
  border-radius: 5px;
  background-color: var(--ion-color-danger);
  color: var(--ion-color-danger-contrast);
  padding: 10px;
  margin-bottom: 10px;
}

/* Customize buttons */
.mdl-button {
  /* background: var(--ion-color-primary) !important; /* Indigo background */
  color: var(--ion-color-dark) !important; /* White text */
  border: 0px !important;
}

/* Customize input fields */
.mdl-textfield__input {
  border-bottom: 2px solid #3949ab !important; /* Indigo underline */
}

/* Customize input label */
.mdl-textfield__label:after {
  background-color: #3949ab !important; /* Indigo highlight */
}

.mdl-card, .mdl-shadow--2dp, .firebaseui-container, .firebaseui-id-page-sign-in {
  background-color: transparent !important; /* Removes background */
  box-shadow: none !important; /* Removes shadow */
  border: none !important; /* Removes borders */
}

/* Change the font for all FirebaseUI elements */
.firebaseui-auth-container {
  font-family: 'Inter';
  margin: 0px !important;
  padding: 0px !important;
}

.firebaseui-card-header {
  background-color: var(--ion-color-light) !important;
}

.firebaseui-card-content {
  background-color: var(--ion-color-light) !important;
}

.firebaseui-card-actions {
  background-color: var(--ion-color-light) !important;
  text-align: left;;
}
/* Text color */
.firebaseui-text {
  color: #333; /* Dark grey text; change as needed */
}

/* Input field styles */
.firebaseui-input {
  background-color: #fff; /* White input backgrounds */
  border: 1px solid #ccc; /* Light grey border */
  color: #333; /* Dark grey text */
}

/* Input focus and hover states */
.firebaseui-input:focus,
.firebaseui-input:hover {
  border-color: #007bff; /* Change to your theme's primary color */
}

/* Button styles */
.firebaseui-idp-button,
.firebaseui-id-submit {
  background-color: #007bff; /* Primary color for buttons */
  color: #ffffff; /* White text */
  border: none; /* No border */
}

/* Button hover state */
.firebaseui-idp-button:hover,
.firebaseui-id-submit:hover {
  background-color: #0056b3; /* Darker shade of the primary color */
  color: #ffffff;
}

/* Error message styles */
.firebaseui-error {
  color: #d9534f; /* Bootstrap's default danger color for errors */
}

/* Link styles */
.firebaseui-link {
  color: #007bff; /* Use the primary color for links */
}

/* This targets the Google button specifically for customization */
.firebaseui-idp-google {
  background-color: #ffffff; /* White background for Google button */
  border: 1px solid #d6d6d6; /* Light grey border */
}

.firebaseui-idp-google .firebaseui-idp-icon-wrapper {
  background-color: #ffffff; /* Ensure the icon's background matches */
}

.firebaseui-idp-google:hover {
  border-color: #c6c6c6; /* Slightly darker border on hover */
}

/* Specific styles for other providers (Facebook, Twitter, etc.) can be added similarly */
